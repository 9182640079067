import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/layout/Layout";
import { Button, ButtonSymbolHover } from "../components/Button";
import Transcript from "../components/Transcript";

import { slug, getDefinedTermPopUpItem } from "../helpers";
import QuoteRow from "../components/QuoteRow"
import ArticleItem from "../components/ArticleItem";
import ShareButtons from "../components/ShareButtons";
import Video from "../components/Video";
import { PageLinkList } from "../components/PageLinkList";
import { LogoCloud } from "../components/LogoCloud";
import ContentHTMLWithHoverPopUps from "../components/ContentHTMLWithHoverPopUps";

export default ({ pageContext, data, location }) => {
	const {
		color,
		ingress,
		additionalHeroLinks,
		heroImage,
		liftImage,
		quoteWidgetId,
		content,
		webinarCtaText,
		webinarCta,
		demoCtaText,
		relatedArticles,
		relatedCustomerCaseArticles,
		createdAt,
		updatedAt,
		metaTags,
		contentVideo,
		pageLinkLists,
		pageLinkListHeader,
		logoCloud
	} = data.contentfulLanding;

	const seoData = {
		updatedAt: updatedAt,
		createdAt: createdAt,
		title: pageContext.name,
		author: "Frends team",
		metaTags: metaTags,
		image: liftImage?.file?.url,
	}

	const definedTermsPopUpItems = data.allContentfulDefinedTerm.nodes.map(getDefinedTermPopUpItem);

	const webinarLink = webinarCta !== null
		? webinarCta.webinarUrl !== undefined && webinarCta.webinarUrl !== null
			? `/webinar/${webinarCta.webinarUrl}`
			: `/webinar/${slug(webinarCta.title)}`
		: null;

	const isLocaleDe = () => pageContext.locale === 'de';

	const getTranslation = (key) => {
		if (key === 'start30DayTrialLink') {
			return isLocaleDe() ? '30-Tage-Testversion starten' : 'start 30-day trial';
		}
		if (key === 'contactUsLink') {
			return isLocaleDe() ? 'Kontaktieren Sie uns' : 'contact us';
		}
		if (key === 'relatedArticlesTitle') {
			return isLocaleDe() ? 'Verwandte Artikel' : 'related articles';
		}
		if (key === 'fillInTheFormText') {
			return isLocaleDe() ? 'füllen Sie das Formular aus' : 'fill in the form';
		}
		if (key === 'seeWhatWeCanDoText') {
			return isLocaleDe() ? 'und sehen Sie, was wir für Sie tun können' : 'and see what we can do for you';
		}
		if (key === 'relatedCustomerArticlesTitle') {
			return isLocaleDe() ? 'verwandte Kundenartikel' : 'related customer articles';
		}
		if (key === 'bookDemoLink') {
			return isLocaleDe() ? 'Buchen Sie eine Demo' : 'Book a demo';
		}
		if (key === 'noTimeForMeetingText') {
			return isLocaleDe() ? 'Keine Zeit für ein Meeting?' : 'No time for a meeting?';
		}
		if (key === 'exploreFrendsText') {
			return isLocaleDe()
				? 'Entdecken Sie Frends eiPaaS 30 Tage lang in Ihrer Freizeit, indem Sie eine Testversion von Frends erhalten. Volle Funktionen und keine Kreditkarte erforderlich'
				: 'Explore Frends eiPaaS in your free time for 30 days by getting Frends trial. Full capabilities and no credit card required';
		};
		return key;
	}

	return (
		<Layout
			title={pageContext.name}
			isLanding={true}
			seoData={seoData}
			location={location}
			language={pageContext.locale}
		>
			<main id="content">
				<section className={`hero is-fullheight-with-navbar`}>
					<div className="hero-body" style={{ position: "relative" }}>
						<div className="content">
							<div className="columns is-mobile">
								<div className="column is-10-widescreen is-offset-1-widescreen is-12-mobile">
									<div className="columns is-mobile is-vcentered is-multiline">
										<div className="column is-8-desktop has-text-centered-mobile">
											<h1 className="title font-variable line-height-100 size-100 is-black" style={{ marginTop: "0px" }}>{pageContext.name}</h1>
											<div className="columns">
												<div className="column is-10-desktop is-12">
													<div className="padding-y-20" dangerouslySetInnerHTML={{ __html: ingress.childMarkdownRemark.html }} />
												</div>
											</div>
											<ButtonSymbolHover
												type="outlined"
												to="/trial"
												outerLink={isLocaleDe()}
												text={getTranslation('start30DayTrialLink')}
												color={color}
												customClass="margin-top-30"
											/>
											{additionalHeroLinks && additionalHeroLinks.map((link, i) => (
												<ButtonSymbolHover
													key={i}
													type="outlined"
													to={link.url}
													text={link.title}
													color={color}
													customClass="margin-top-30 ml-5"
												/>
											))}
										</div>
										<div className="column is-4-desktop is-hidden-mobile is-hidden-desktop-only has-text-centered">
											<GatsbyImage image={getImage(heroImage)} style={{ borderRadius: "50%" }} alt="" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{quoteWidgetId && (
					<section className="hero">
						<div className="hero-body">
							<div className="container customer-content content">
								<QuoteRow id={quoteWidgetId} />
							</div>
						</div>
					</section>
				)}
				<section className={`section is-medium is-${color}-bg`}>
					<div className="container content contentful-content">
						<div className="columns">
							<div className="column is-four-fifths is-offset-1">
								{contentVideo !== null && contentVideo !== undefined && (
									<Video src={contentVideo.file.url} />
								)}
								<ContentHTMLWithHoverPopUps
									contentHTML={content.childMarkdownRemark.html}
									popUpItems={definedTermsPopUpItems}
								/>
								<ButtonSymbolHover
									color={color}
									text={getTranslation('contactUsLink')}
									outerLink={isLocaleDe()}
									type="outlined"
									to="/ask-us-anything"
									customClass="margin-top-30"
								/>
								<ShareButtons url={location.href} title={`Read: ${pageContext.name}`} />
							</div>
						</div>
					</div>
				</section>
				{webinarCta !== null && (
					<section className={`section is-medium`}>
						<div className="container content">
							<div className="columns is-centered">
								<div className="column is-hidden-mobile" style={{ textAlign: "center" }}>
									<Button type="outlined" to={webinarLink} text={webinarCtaText} color={color} customStyles={{ textAlign: "center" }} outerLink={isLocaleDe()} />
								</div>
								<div className="is-hidden-tablet">
									<div className="column" style={{ textAlign: "center" }}>
										<Button textStyles={{ whiteSpace: "normal" }} customStyles={{ height: "100%" }} type="outlined" to={webinarLink} text={webinarCtaText} color={color} outerLink={isLocaleDe()} />
									</div>
								</div>
							</div>
						</div>
					</section>
				)}
				{relatedArticles !== null && (
					<section className="hero is-medium">
						<div className="hero-body">
							<div className="container">
								<h2 className="ingress size-40">
									{getTranslation('relatedArticlesTitle')}
								</h2>
								<div className="columns is-mobile is-multiline">
									{relatedArticles.map((post, i) => (
										<div className="column is-4-desktop is-12-mobile" key={i}>
											<ArticleItem
												item={post}
												color={color}
												locale={pageContext.locale}
											/>
										</div>
									))}
								</div>
							</div>
						</div>
					</section>
				)}
				{logoCloud && (
					<LogoCloud logos={logoCloud.logo} />
				)}
				<Transcript
					iconColor="white"
					link="/ask-us-anything"
					outerLink={isLocaleDe()}
					title={getTranslation('fillInTheFormText')}
					subtitle={getTranslation('seeWhatWeCanDoText')}
					backgroundImage={data}
					textColor="white"
				/>
				{relatedCustomerCaseArticles !== null && (
					<section className="hero is-medium">
						<div className="hero-body">
							<div className="container">
								<h2 className="ingress size-40">
									{getTranslation('relatedCustomerArticlesTitle')}
								</h2>
								<div className="columns is-mobile is-multiline">
									{relatedCustomerCaseArticles.map((post, i) => (
										<div className="column is-4-desktop is-12-mobile" key={i}>
											<ArticleItem
												item={post}
												color={color}
												locale={pageContext.locale}
											/>
										</div>
									))}
								</div>
							</div>
						</div>
					</section>
				)}
				<section className={`section is-medium is-${color}-bg`}>
					<div className="container content">
						<div className="columns">
							<div className="column is-three-fifths is-offset-one-fifth">
								<p dangerouslySetInnerHTML={{ __html: demoCtaText.childMarkdownRemark.html }} />

								<ButtonSymbolHover
									type="outlined"
									to="/demo"
									outerLink={isLocaleDe()}
									text={getTranslation('bookDemoLink')}
									color={color}
								/>
								<p>
									{getTranslation('noTimeForMeetingText')}
								</p>
								<p>
									{getTranslation('exploreFrendsText')}
								</p>
								<ButtonSymbolHover
									type="outlined"
									to="/trial"
									outerLink={isLocaleDe()}
									text={getTranslation('start30DayTrialLink')}
									color={color}
								/>
							</div>
						</div>
					</div>
				</section>
				{pageLinkLists !== null && (
					<section className={`section is-medium`}>
						<div className="container content">
							<div className="columns">
								<div className="column is-three-fifths is-offset-one-fifth">
									<PageLinkList
										lists={pageLinkLists}
										header={pageLinkListHeader}
										locale={pageContext.locale}
									/>
								</div>
							</div>
						</div>
					</section>
				)}
			</main>
		</Layout>
	);
}

export const query = graphql`query ($contentful_id: String!, $locale: String, $contentful_content_vocabulary_item_slugs: [String]) {
  contentfulLanding(
    node_locale: {eq: $locale}
    contentful_id: {eq: $contentful_id}
  ) {
    metaTags
    updatedAt
    createdAt
		quoteWidgetId
    color
    pageLinkListHeader
    pageLinkLists {
      linkList {
        ... on ContentfulWebinarV2 {
			__typename
          id
          title
          webinarUrl
          webinarFreeText {
            childMarkdownRemark {
              excerpt
            }
          }
          webinarImage {
            gatsbyImageData(height: 400, width: 400)
          }
        }
        ... on ContentfulServicesSubpage {
			__typename
          id
          title
          slug
          ingress {
            childMarkdownRemark {
              excerpt
            }
          }
          heroImage {
            gatsbyImageData(height: 400, width: 400)
          }
        }
        ... on ContentfulLanding {
			__typename
          id
          title
          slug
          ingress {
            childMarkdownRemark {
              excerpt
            }
          }
          heroImage {
            gatsbyImageData(height: 400, width: 400)
          }
        }
        ... on ContentfulCustomerType {
			__typename
          id
          name
          slug
          ingress {
            childMarkdownRemark {
              excerpt
            }
          }
          heroImage {
            gatsbyImageData(width: 400, height: 400)
          }
        }
        ... on ContentfulCustomer {
			__typename
          id
          name
          description
          slug
          heroImage {
            gatsbyImageData(height: 400, width: 400)
          }
        }
        ... on ContentfulCoSellPartner {
			__typename
          id
          name
          slug
          slogan
        }
        ... on ContentfulArticle {
			__typename
          id
          title
          articleUrl
          subtitle
          heroImage {
            gatsbyImageData(width: 400, height: 400)
          }
        }
      }
      title
    }
    contentVideo {
      file {
        url
      }
    }
    heroImage {
      gatsbyImageData(width: 600, height: 600)
      title
    }
	liftImage {
	  file {
	    url
	  }
	}
    ingress {
      childMarkdownRemark {
        html
      }
    }
	additionalHeroLinks {
	  title
	  url
	}
    content {
      childMarkdownRemark {
        html
      }
    }
    demoCtaText {
      childMarkdownRemark {
        html
      }
    }
    webinarCtaText
    webinarCta {
      webinarUrl
      title
    }
    logoCloud {
      logo {
        title
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
      }
    }
    relatedArticles {
      title
      articleUrl
      color
      subtitle
      date(formatString: "DD.MM.YYYY")
      category
      liftImage {
        gatsbyImageData(width: 1820, quality: 100)
        title
      }
    }
    relatedCustomerCaseArticles {
      title
      articleUrl
      color
      subtitle
      date(formatString: "DD.MM.YYYY")
      category
      liftImage {
        gatsbyImageData(width: 1820, quality: 100)
        title
      }
    }
  }
  bg2: file(relativePath: {eq: "images/new_purple_background2.png"}) {
    childImageSharp {
      gatsbyImageData(width: 1918, placeholder: NONE, layout: FIXED)
    }
  }
  allContentfulDefinedTerm(
    filter: {slug: {in: $contentful_content_vocabulary_item_slugs}, node_locale: {eq: $locale}}
    ) {
    nodes {
        slug
        title
        description {
            childMarkdownRemark {
                html
                excerpt
            }
        }
    }
  }
}
`
